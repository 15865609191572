import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./index.css";
import "./assets/tailwind.css";
import { createPinia } from 'pinia'
import "./axios";
import i18n from "./i18n"
import { register } from 'swiper/element/bundle';


register();


const pinia = createPinia()

import { markRaw } from 'vue'

pinia.use(({ store }) => {
  store.$router = markRaw(router)
})

const app = createApp(App);
app.use(router).use(pinia).use(i18n).mount("#app");
