<template>
  <div class="px-4 sm:px-6 lg:px-8 container mx-auto">
    <div class="flex justify-between items-center">
      <h1 class="text-2xl font-semibold text-orange-500 pt-4">
        {{$t('title.featured')}}
      </h1>
    </div>
    
    <Carousel
      :items-to-show="1"
      v-model="currentSlide"
      class="carousel"
      :mouse-drag="false"
    >
      <Slide v-for="(item, index) in featured" :key="index">
        <div
          class="sm:p-5 text-left lg:h-80 overflow-hidden"
        >
          <div class="sm:p-5 lg:grid lg:grid-cols-6 gap-10">
            <img class="rounded-sm" :src="item.CInfo_Image" @error="handleImageError($event)" />
            <div class="flex flex-col col-span-5">
              <router-link :to="{name: 'Search', query: {artist: item.itd_Desc3}}" class="mb-1 font-semibold text-blue-700 hover:text-blue-900">{{ item.itd_Desc3 }}</router-link>
              <span class="mb-3">{{ item.itd_Desc4 }}</span>
              <span class="text-sm">{{ item.CInfo_Data }}</span>
            </div>
          </div>
        </div>
      </Slide>
    </Carousel>
  </div>

  <div class=""> <!--bg-blue-50 rounded-sm shadow-md-->
    <div class="container mx-auto pb-6">
      <Carousel
        class="hover:cursor-pointer"
        id="thumbnails"
        :items-to-show="2"
        :wrap-around="true"
        v-model="currentSlide"
        ref="carousel"
        :breakpoints="breakpoints"
      >
        <Slide v-for="(item, index) in featured" :key="index">
          <div class="carousel__item flex flex-col overflow-hidden" @click="slideTo(index)">
            <img
              @error="handleImageError($event)"
              :class="currentSlide == index ? 'scale-120 shadow-xl' : 'scale-100 shadow-sm hover:shadow-xl hover:scale-110'"
              class="rounded-sm aspect-square object-contain bg-gray-100 m-5 h-24 w-24 mx-auto transition-all"
              :src="item.CInfo_Image"
            />
            <span class="block font-semibold text-sm">{{ item.itd_Desc3 }}</span>
            <span class="block truncate">{{ item.itd_Desc4 }}</span>
          </div>
        </Slide>
      </Carousel>
    </div>
  </div>
  <div class="container mx-auto p-10 grid lg:grid-cols-3 gap-4">
    <ItemSlider v-if="thisWeek" :items="thisWeek" :info="{title: 'Shipping This Week', to: {name: 'ShipThisWeek'}, icon: 'calendar'}"/>
    
    <ItemSlider v-if="nextWeek" :items="nextWeek" :info="{title: 'Shipping Next Week', to: {name: 'ShipNextWeek'}, icon: 'calendar'}"/>

    <ItemSlider v-if="staffPicks" :items="staffPicks" :info="{title: 'Staff Picks', to: {name: 'StaffPicks'}, icon: 'star'}"/>
  </div>
</template>
<script>
import { useMainStore } from "@/stores/mainStore";
import { Carousel, Slide } from "vue3-carousel";
import ItemSlider from "./ItemSlider.vue";
import "vue3-carousel/dist/carousel.css";
import axios from "@/axios";
export default {
  components: {
    Carousel,
    Slide,
    ItemSlider
  },
  data() {
    return {
      featured: null,
      currentSlide: 0,
      thisWeek: null,
      nextWeek: null,
      staffPicks: null,
      breakpoints: {
      // 768 and up
      768: {
        itemsToShow: 4,
      },
      // 1280 and up
      1280: {
        itemsToShow: 6.5,
      },
    },
    };
  },
  setup() {
    const mainStore = useMainStore();
    return {mainStore};
  },
  methods: {
    slideTo(val) {
      this.currentSlide = val;
    },
    handleImageError(event) {
      event.target.src = require('@/assets/logos/logo-fab-letters.png');
    },
    async getContent() {
      await axios
        .get("/public/featured", {
          params: {
            
          Flag: 4
          }
        })
        .then((response) => {
          this.featured = response.data.Table;
        });
    },
    async getItems() {
      try {
        const response = await axios.post("/SP/Post/sp_ProductListGet4", {
          Active: 1,
          LangCode: 1,
          PreDefinedSearch: 21,
          months: 0,
          OrderNumber: this.mainStore?.orderNo,
          enteredvalue: null,
          custcode: this.mainStore?.workingCustCode,
          sorting_direction: 'ASC',
          sorting_field: 'Artist_itd_Desc3'
        });
        this.thisWeek = response.data.Table;
        const response2 = await axios.post("/SP/Post/sp_ProductListGet4", {
          Active: 1,
          LangCode: 1,
          PreDefinedSearch: 22,
          months: 0,
          OrderNumber: this.mainStore?.orderNo,
          enteredvalue: null,
          custcode: this.mainStore?.workingCustCode,
          sorting_direction: 'ASC',
          sorting_field: 'Artist_itd_Desc3'
        });
        this.nextWeek = response2.data.Table;
        const response3 = await axios.post("/SP/Post/sp_ProductListGet4", {
          Active: 1,
          LangCode: 1,
          PreDefinedSearch: 20,
          months: 0,
          OrderNumber: this.mainStore?.orderNo,
          enteredvalue: null,
          custcode: this.mainStore?.workingCustCode,
          sorting_direction: 'ASC',
          sorting_field: 'Artist_itd_Desc3'
        });
        this.staffPicks = response3.data.Table;
      } catch (e) {
        e;
      }
    }
  },
  mounted() {
    this.getContent();
    if(this.mainStore.isAuth) {
      this.getItems();
    }
  }
};
</script>
