<template>
  <div class="mt-4">
    <div class="w-full h-1 bg-blue-900"></div>
    <div class="w-full pb-6 bg-blue-100">
      <div class="container mx-auto sm:px-6 lg:px-8 flex flex-col lg:flex-row justify-between py-4">
        <div class="h-100 flex flex-col lg:flex-row gap-6 items-center">
          <div>
            <img
              class="h-12 w-auto"
              src="@/assets/logos/logo-fab-letters.png"
              alt="FAB"
            />
          </div>
          <ul class="flex flex-col lg:flex-row gap-4 text-sm text-left font-semibold">
            <li><router-link :to="{name: 'Content', params: {id: 3}}" class="hover:underline">{{ $t('nav.about') }}</router-link></li>
            <li><router-link :to="{name: 'Content', params: {id: 6}}" class="hover:underline">{{ $t('nav.community') }}</router-link></li>
            <li><router-link :to="{name: 'Content', params: {id: 8}}" class="hover:underline">{{ $t('nav.privacy') }}</router-link></li>
            <li><router-link :to="{name: 'Content', params: {id: 7}}" class="hover:underline">{{ $t('nav.terms') }}</router-link></li>
          </ul>
        </div>

        <div class="flex gap-4 mx-auto lg:mx-0 mt-2 lg:mt-0 items-center">
          <span class="text-sm text-gray-700 font-semibold">{{ $t('label.followUs') }}</span>
          <ul class="text-sm flex justify-center gap-2">
            <li>
              <a href="https://www.facebook.com/fabdistro/" target="_blank" class="block p-1.5 bg-orange-500 hover:bg-orange-600 border-2 border-white rounded-full">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 24 24" role="img">
                  <title>Facebook</title>
                  <g fill="#fff">
                    <path d="M17.525,9H14V7c0-1.032,0.084-1.682,1.563-1.682h1.868v-3.18C16.522,2.044,15.608,1.998,14.693,2 C11.98,2,10,3.657,10,6.699V9H7v4l3-0.001V22h4v-9.003l3.066-0.001L17.525,9z"></path>
                  </g>
                </svg>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/fabdistro/" target="_blank" class="block p-1.5 bg-orange-500 hover:bg-orange-600 border-2 border-white rounded-full">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 24 24" role="img">
                  <title>Instagram</title>
                  <g fill="#fff">
                    <path d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z"></path>
                  </g>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
