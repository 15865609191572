<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        class="fixed inset-0 flex z-40"
        @close="mainStore.sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div
            class="
              relative
              flex-1 flex flex-col
              max-w-xs
              w-full
              pt-5
              pb-4
              bg-gray-800
            "
          >
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  class="
                    ml-1
                    flex
                    items-center
                    justify-center
                    h-10
                    w-10
                    rounded-full
                    focus:outline-none
                    focus:ring-2
                    focus:ring-inset
                    focus:ring-white
                  "
                  @click="mainStore.sidebarOpen = false"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="flex-shrink-0 flex items-center px-4 m-auto">
              <img
                class="h-12 w-auto"
                src="@/assets/logos/econsole-lt.png"
                alt="Workflow"
              />
            </div>
            <div class="mt-5 flex-1 h-0 overflow-y-auto">
              <nav class="px-2 space-y-1">
                <router-link @click="mainStore.sidebarOpen = false" v-slot="{isActive}" v-for="item in navigation" :key="item.name" :to="item.href" :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center px-2 py-4 text-base font-medium rounded-sm']">
                  <component :is="item.icon" :class="[isActive ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300', 'mr-4 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                  {{ item.name }}
                </router-link>
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  BellIcon,
  CalendarIcon,
  //MusicNoteIcon,
  FolderIcon,
  //HomeIcon,
  //InboxIcon,
  MenuAlt2Icon,
  UsersIcon,
  XIcon,
  //BookOpenIcon,
  //ArchiveIcon,
  PencilAltIcon
} from "@heroicons/vue/outline";
import { SearchIcon } from "@heroicons/vue/solid";
import { useMainStore } from "@/stores/mainStore";

const navigation = [
  // {
  //   name: "Dashboard",
  //   href: { name: "DashBoard" },
  //   icon: HomeIcon,
  //   current: false,
  // },
  {
    name: "User Maintenance",
    href: { name: "UserList" },
    icon: UsersIcon,
    current: false,
  },
  {
    name: "Customer Maintenance",
    href: { name: "CustList" },
    icon: FolderIcon,
    current: false,
  },
  {
    name: "Orders & Invoices",
    href: { name: "Orders" },
    icon: CalendarIcon,
    current: false,
   },
  // {
  //   name: "Supplier Management",
  //   href: { name: "SupplierList" },
  //   icon: InboxIcon,
  //   current: false,
  // },
  // {
  //   name: "Products",
  //   href: { name: "ProductList" },
  //   icon: MusicNoteIcon,
  //   current: false,
  // },
  // {
  //   name: "More",
  //   href: { name: "MorePage" },
  //   icon: BookOpenIcon,
  //   current: false,
  // },
  // {
  //   name: "Sales",
  //   href: { name: "MoreSales" },
  //   icon: ArchiveIcon,
  //   current: false,
  // },
  {
    name: "Content Management",
    href: { name: "ContentNews" },
    icon: PencilAltIcon,
    current: false,
  },
];
const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
];

export default {
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    MenuAlt2Icon,
    SearchIcon,
    XIcon,
  },
  setup() {
    const mainStore = useMainStore();

    return {
      navigation,
      userNavigation,
      mainStore,
    };
  },
  computed: {
    sidebarOpen() {
      return this.mainStore.sidebarOpen;
    },
  },
};
</script>