<template>
  <div :class="show ? 'bottom-3' : 'bottom-[-50px]'" class="transition-all block right-3 fixed z-50">
    <button
      @click="scrollToTop"
      title="Scroll To Top"
      class="text-blue-700 bg-white rounded-full p-1 ring-2 ring-blue-700 hover:text-blue-900 transition-colors"
    >
      <ArrowUpIcon class="h-6" />
    </button>
  </div>
</template>
<script setup>
import { ArrowUpIcon } from "@heroicons/vue/outline";
import { onMounted, onBeforeUnmount, ref } from "vue";
function scrollToTop() {
  window.scrollTo({ top: 0, behavior: "smooth" });
}
const show = ref(false);
function handleScroll() {
    if(window.scrollY > 200) {
        show.value = true;
        return;
    }
    show.value = false;
}
onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});
onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});

</script>
