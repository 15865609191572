import { createI18n } from "vue-i18n"
//import pluralRules from "./rules/pluralization"
//import numberFormats from "./rules/numbers.js"
//import datetimeFormats from "./rules/datetime.js"
import en from "./locales/en.json"
import fr from "./locales/fr.json"

export default createI18n({
  locale: 'en',
  fallbackLocale: 'fr',
  legacy: false,
  globalInjection: true,
  messages: { en, fr },
  runtimeOnly: false,
//   pluralRules,
//   numberFormats,
//   datetimeFormats
})