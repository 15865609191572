<template>
  <div v-if="props.items" class="w-2/3 lg:w-full">
      <div class="flex justify-between">
        <div>
          <router-link :to="info.to" class="text-xl font-bold text-blue-700 hover:text-blue-900 flex gap-2">
            <CalendarIcon v-if="info.icon == 'calendar'" class="h-5 my-auto"/>
            <StarIcon v-if="info.icon == 'star'" class="h-5 my-auto"/>
            {{ info.title }}
          </router-link>
        </div>
        <button @click="scrollUp" class="hover:bg-gray-100">
          <ChevronUpIcon class="h-10" />
        </button>
      </div>
      <div class="swiper-div border rounded-sm shadow-sm">
        <swiper-container
          ref="swiperEl"
          init="false"
        >
        <swiper-slide v-for="(item, index) in props.items" :key="index">
              <router-link  class="flex flex-col text-left hover:bg-blue-50 transition-all w-full p-1" :to="{name: 'Search', query: {artist: item.itd_Desc3, title: item.it_Title}}">
              <span class="font-semibold truncate">{{ item.itd_Desc3 }}</span>
              <span class="truncate">{{ item.it_Title }}</span>
              </router-link>
          </swiper-slide>
        </swiper-container>
      </div>
      <div class="flex justify-end">
        <button @click="scrollDown" class="hover:bg-gray-100">
          <ChevronDownIcon class="h-10" />
        </button>
      </div>
  </div>
</template>

<script setup>
import { defineProps, ref, onMounted } from "vue";
import { ChevronUpIcon, ChevronDownIcon, CalendarIcon, StarIcon } from "@heroicons/vue/outline";
import { register } from "swiper/element";
register();
const swiperParams = {
  loop: true,
  slidesPerGroup: 5,
  slidesPerView: 5,
  direction: 'vertical'
}
const props = defineProps(["items", "info"]);
const swiperEl = ref();


onMounted(() => {
  //const swiperEl = document.querySelector('swiper-container');
  Object.assign(swiperEl.value, swiperParams);
  swiperEl.value.initialize();
})
function scrollUp() {
  swiperEl.value.swiper.slidePrev();
}
function scrollDown() {
  swiperEl.value.swiper.slideNext();
}
</script>
<style scoped>
swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-div {
  position: relative;
  height: 375px;
}
swiper-slide {
  text-align: left;
  display: flex;
  justify-content: start;
  align-items: center;
}
</style>

