<template>
  <div class="flex flex-col min-h-screen">
    <ScrollToTop />
    <NavBar v-if="!$route.meta.hideNavbar"></NavBar>
    <SideBar class="sidebar"></SideBar>
    <div class="flex-grow">
      <router-view />
    </div>
    <FooterBar
      v-if="$route.meta.requiresAdmin != true && $route.meta.hideFooter != true"
    />
  </div>
</template>
<script>
import ScrollToTop from "./components/ScrollToTop.vue";
import NavBar from "./components/NavBar.vue";
import SideBar from "./components/SideBar.vue";
import FooterBar from "./components/FooterBar.vue";
import { useMainStore } from "@/stores/mainStore";
export default {
  components: {
    NavBar,
    SideBar,
    FooterBar,
    ScrollToTop
  },
  setup() {
    const mainStore = useMainStore();
    return {
      mainStore
    };
  },
  data() {
    return {
      loading: false
    };
  }
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#sidebar {
  padding: 30px 0;
}

.sidebar,
nav a.router-link-exact-active {
  color: #f97316;
}
</style>
