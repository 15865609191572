<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="container sticky md:relative top-0 mx-auto py-5 bg-white z-40 shadow-sm">
    <div class="w-full flex justify-between items-center flex-wrap">
      <div class="hidden w-1/3 lg:flex justify-start">
        <button
          v-if="mainStore.isAdmin"
          type="button"
          class="flex items-center ml-6 text-blue-800 hover:text-blue-900 transition-all"
          @click="mainStore.sidebarOpen = true"
        >
          <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
          <span class="ml-1.5">Console</span>
        </button>
      </div>

      <div class="w-1/2 lg:w-1/3 flex justify-center">
        <router-link :to="{ name: 'Home' }" class="ml-3 lg:ml-0">
          <img
            class="block w-auto max-w-full max-h-14 mx-auto"
            src="@/assets/logos/logo-fab.png"
            alt="FAB"
          />
        </router-link>
      </div>
      <div class="w-1/3 flex justify-end">
        <button
          v-if="user == true"
          :disabled="cartStore.header?.CartCount==0 || cartStore.header == ''"
          @click="$router.push({ name: 'Cart' })"
          type="button"
          class="flex mr-6 items-center px-1 pt-1 relative text-blue-800 hover:text-blue-900"
        >
          <span class="mr-1.5">{{ $t('nav.myCart') }}</span>
          <ShoppingCartIcon class="h-7 w-7" aria-hidden="true" />
          <span class="absolute px-2 py-0.5 -top-1.5 -right-1.5 rounded-full text-xs font-medium bg-blue-100 text-blue-900"> {{cartStore.header?.CartCount ? cartStore.header.CartCount : 0}}</span>
        </button>
      </div>
    </div>
  </div>
    

  <Disclosure as="nav" class="w--auto lg:w-full bg-blue-50 shadow" v-slot="{ open }">
    <div class="container mx-auto">
      <div class="flex justify-between h-16">
        <div class="flex">
          <!-- Mobile menu button -->
          <DisclosureButton
            class="px-5 mr-2 lg:hidden text-gray-500 transition-all focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          >
            <span class="sr-only">Open main menu</span>
            <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>

          <div class="hidden lg:ml-6 lg:flex lg:space-x-8">
            <!-- Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->
           
            <router-link
              :to="{ name: 'Home' }"
              class="border-transparent inline-flex text-gray-700 hover:border-gray-300 items-center px-1 pt-1 border-b-2 text"
              active-class="border-indigo-500"
            >
              {{ $t('nav.home') }}
            </router-link>
            <router-link
              :to="{ name: 'Digest' }"
              class="border-transparent text-gray-700 hover:border-gray-300 inline-flex items-center px-1 pt-1 border-b-2 text"
              active-class="border-indigo-500"
            >
            {{ $t('nav.digest') }}
            </router-link>
            <router-link
            v-if="mainStore.isAuth"
              :to="{ name: 'SalesSheet' }"
              class="border-transparent text-gray-700 hover:border-gray-300 inline-flex items-center px-1 pt-1 border-b-2 text"
              active-class="border-indigo-500"
            >
            {{ $t('nav.salesSheet') }}
            </router-link>

            <Menu v-if="mainStore.isAuth" as="div" class="relative inline-flex text-left">
              <div
                class="inline-flex border-transparent items-center px-1 pt-1 border-b-2 hover:border-gray-300"
              >
                <MenuButton class="text-gray-700 inline-flex">
                  <a>{{ $t('nav.more') }}</a>
                  <ChevronDownIcon
                    class="-mr-1 ml-2 h-5 w-5"
                    aria-hidden="true"
                  />
                </MenuButton>
              </div>

              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-0 top-14 w-56 rounded-sm shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                >
                  <div class="py-1">
                    <MenuItem v-slot="{ active }">
                      <router-link
                        :to="{ name: 'ShipThisWeek' }"
                        :class="[
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        ]"
                        >{{ $t('nav.thisWeek') }}</router-link
                      >
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <router-link
                        :to="{ name: 'ShipNextWeek' }"
                        :class="[
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        ]"
                        >{{ $t('nav.nextWeek') }}</router-link
                      >
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <router-link
                        :to="{ name: 'StaffPicks' }"
                        :class="[
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        ]"
                        >{{ $t('nav.staffPicks') }}</router-link
                      >
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <router-link
                        :to="{name: 'TourInfo'}"
                        :class="[
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        ]"
                        >{{ $t('nav.tourDates') }}</router-link
                      >
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <router-link
                        :to="{name: 'ItemSale'}"
                        :class="[
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        ]"
                        >{{ $t('nav.itemSale') }}</router-link
                      >
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
        
        <div class="hidden lg:mx-6 lg:flex lg:space-x-8">
          <router-link
          v-if="mainStore.isAuth"
            to="/search"
            class="border-transparent text-gray-700 hover:border-gray-300 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
          >
            <SearchIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            <span>{{ $t('nav.search') }}</span>
          </router-link>
          <template
            v-if="user == true"
          >

            <!-- Profile dropdown -->
            <Menu as="div" class="border-transparent relative text-gray-700 hover:border-gray-300 px-1 pt-1 border-b-2 text-sm font-medium">
                <MenuButton class="inline-flex h-full items-center"
                >
                  <span class="truncate">{{ mainStore.locationName ? mainStore.locationName : mainStore.user.Email }}</span>
                  <ChevronDownIcon
                    class="-mr-1 ml-2 h-5 w-5"
                    aria-hidden="true"
                  />
                </MenuButton>
              <transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="origin-top-right absolute right-0 mt-2 w-48 rounded-sm shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                >
                <MenuItem class="border-b">
                    <span class="block px-4 truncate py-2 text-center text-sm text-black hover:cursor-default">{{ mainStore.user.Email }}</span>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <router-link
                      :to="{name: 'HistoryOrders'}"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700'
                      ]"
                      >{{ $t('nav.orderHistory') }}</router-link
                    >
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <router-link
                      :to="{ name: 'Location' }"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700'
                      ]"
                    >
                      {{
                        mainStore.locationName
                           ? $t('nav.changeLocation')
                          : $t('nav.selectLocation')
                      }}</router-link
                    >
                  </MenuItem>
                  <MenuItem as="a" @click="signOut()" v-slot="{ active }">
                    <a
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                      ]"
                      >{{ $t('nav.signOut') }}</a
                    >
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </template>
          
          <button
          v-if="user == false"
            @click="$router.push({ name: 'SignIn' })"
            type="button"
            class="pl-10 transition-colors relative inline-flex items-center px-4 my-3 border border-transparent text-sm font-medium rounded-sm text-white bg-orange-500 shadow-sm hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
          >
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                <UserIcon
                  class="h-5 w-5 text-orange-400 group-hover:text-orange-300"
                  aria-hidden="true"
                />
            </span>
            {{ $t('nav.signIn') }}
          </button>

          <button @click="switchLanguage()" class="border-transparent text-gray-700 hover:border-gray-300 inline-flex items-center px-1 pt-1 border-b-2">
            {{ $t('locale.otherTitle') }}
          </button>
        </div>
        <button @click="switchLanguage()" class="lg:hidden border-transparent text-gray-700 hover:border-gray-300 inline-flex items-center mr-2 px-1 pt-1 border-b-2">
            {{ $t('locale.otherTitle') }}
        </button>
      </div>
      <BreadCrumb class="hidden"></BreadCrumb>
    </div>

    <DisclosurePanel class="lg:hidden">
      <div class="pt-2 pb-3 space-y-1 text-left">
        <!-- Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" -->
        <router-link
          :to="{name: 'Home'}"
          exact-active-class="border-orange-500"
          class="border-transparent text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
          >{{ $t('nav.home') }}</router-link>
        <router-link
        :to="{name: 'Digest'}"
        exact-active-class="border-orange-500"
          class="border-transparent text-gray-700 hover:bg-gray-50 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
          >{{ $t('nav.digest') }}</router-link
        >
        <router-link
        v-if="mainStore.isAuth"
        :to="{name: 'SalesSheet'}"
          class="border-transparent text-gray-700 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
          >{{ $t('nav.salesSheet') }}</router-link
        >
        <router-link
        v-if="mainStore.isAuth"
        :to="{name: 'Search'}"
          class="border-transparent text-gray-700 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
          >{{ $t('nav.search') }}</router-link
        >
        <Menu v-if="mainStore.isAuth" as="div" class="relative inline-flex text-left">
              <div
                class="border-transparent text-gray-700 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
              >
                <MenuButton class="text-gray-700 inline-flex">
                  <a>{{ $t('nav.more') }}</a>
                  <ChevronDownIcon
                    class="-mr-1 ml-2 h-5 w-5"
                    aria-hidden="true"
                  />
                </MenuButton>
              </div>

              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute left-[0px] top-14 w-56 rounded-sm shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                >
                  <div class="py-1">
                    <MenuItem v-slot="{ active }">
                      <router-link
                        :to="{ name: 'ShipThisWeek' }"
                        :class="[
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        ]"
                        >{{ $t('nav.thisWeek') }}</router-link
                      >
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <router-link
                        :to="{ name: 'ShipNextWeek' }"
                        :class="[
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        ]"
                        >{{ $t('nav.nextWeek') }}</router-link
                      >
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <router-link
                        :to="{ name: 'StaffPicks' }"
                        :class="[
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        ]"
                        >{{ $t('nav.staffPicks') }}</router-link
                      >
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <router-link
                        :to="{name: 'TourInfo'}"
                        :class="[
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        ]"
                        >{{ $t('nav.tourDates') }}</router-link
                      >
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <router-link
                        :to="{name: 'ItemSale'}"
                        :class="[
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        ]"
                        >{{ $t('nav.itemSale') }}</router-link
                      >
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
      </div>
      <div v-if="mainStore.isAuth" class="pt-4 pb-3 border-t border-gray-200">
        <div class="flex w-full text-left px-4 sm:px-6 text-sm font-medium text-gray-500"><span class="w-full">{{ mainStore.locationName ? mainStore.locationName : mainStore.user.Email }}</span>
        </div>
        <div class="mt-3 text-left space-y-1">
          <router-link
            :to="{name: 'Location'}"
            class="block px-4 py-2 text-base font-medium text-gray-700 hover:text-gray-800 hover:bg-gray-100 sm:px-6"
            >{{
                        mainStore.locationName
                           ? $t('nav.changeLocation')
                          : $t('nav.selectLocation')
                      }}</router-link
          >
          <router-link
            :to="{name: 'HistoryOrders'}"
            class="block px-4 py-2 text-base font-medium text-gray-700 hover:text-gray-800 hover:bg-gray-100 sm:px-6"
            >{{ $t('nav.orderHistory') }}</router-link
          >
          <DisclosureButton
            as="button"
            @click="signOut()"
            class="block px-4 w-full text-left py-2 text-base font-medium text-gray-700 hover:text-gray-800 hover:bg-gray-100 sm:px-6"
            >Sign out</DisclosureButton
          >
        </div>
      </div>
      <div v-else class="pt-4 pb-3 border-t border-gray-200">
        <div class="mt-3 space-y-1">
         
          <router-link
            as="router-link"
            :to="{name: 'SignIn'}"
            class="block px-4 w-full py-2 text-base font-medium text-gray-700 hover:text-gray-800 hover:bg-gray-100 sm:px-6"
            >Sign In</router-link
          >
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import { useMainStore } from "@/stores/mainStore";
import {useCartStore} from "@/stores/cartStore"
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems
} from "@headlessui/vue";
import {
  ShoppingCartIcon,
  MenuIcon,
  XIcon,
  MenuAlt2Icon,
  SearchIcon
} from "@heroicons/vue/outline";
import { ChevronDownIcon, UserIcon } from "@heroicons/vue/solid";

import { useI18n } from 'vue-i18n'
import { useRouter } from "vue-router"
import Tr from "@/i18n/translation"

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ShoppingCartIcon,
    MenuIcon,
    SearchIcon,
    XIcon,
    BreadCrumb,
    MenuAlt2Icon,
    ChevronDownIcon,
    UserIcon
  },
  setup() {
    const mainStore = useMainStore();
    const cartStore = useCartStore();

    const router = useRouter()

    const { t, locale } = useI18n()
    const supportedLocales = Tr.supportedLocales

    const switchLanguage = async () => {
      const newLocale = t('locale.other')

      await Tr.switchLanguage(newLocale)

      try {
        await router.replace({ params: { locale: newLocale } })
      } catch(e) {
        console.log(e)
        router.push("/")
      }
    }

    return { mainStore, cartStore, t, locale, supportedLocales, switchLanguage };
  },
  computed: {
    user() {
      if (this.mainStore.user) {
        return true;
      }
      return false;
    }
  },

  methods: {
    signOut() {
      this.mainStore.signOut();
    }
  }
};
</script>
